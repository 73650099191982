import React from 'react'
import { TranslatedBlock } from 'src/components/language';
import '../pages/home/index.scss'

const ThingsToKnow = () => {
  return (
    <section className="py-5 primary22-bg">
          <div className="container">
            <TranslatedBlock language="english">
              <h2 className="mb-3 secondary3-color">What should I know while being given VABYSMO?</h2>
            </TranslatedBlock>
            <div className="row">
              <div className="col-12 col-lg-6">
                  <div>
                    <p>
                      <b>Things you should do:</b>
                    </p>
                    <ul>
                      <li>If you experience any problems during the treatment, tell your doctor.</li>
                    </ul>
                  </div>
                  <div>
                    <p>
                      <b>Call your doctor straight away if you:</b>
                    </p>
                    <ul>
                      <li>
                        develop sudden vision loss or change in vision
                      </li>
                      <li>
                        develop signs of a possible eye infection or inflammation,
                         such as worsening redness of the eye, eye pain, increased eye discomfort,
                         blurred or decreased vision, an increased number of floaters (small particles)
                         in your vision, increased sensitivity to light
                      </li>
                    </ul>
                    <p >
                      Remind any doctor or dentist you visit that you are using VABYSMO.
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>Things you should not do:</b>
                    </p>
                    <ul>
                      <li>Do not stop treatment with VABYSMO unless your doctor tells you to.</li>
                    </ul>
                    <p>
                      Stopping treatment may increase your risk of vision loss and your vision may worsen.
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>It is important for you to know that:</b>
                    </p>
                    <ul>
                      <li>there is no information about injecting VABYSMO into both eyes at the same time. Use in this way may lead to an increased
                         risk of experiencing side effects.</li>
                      <li>injections with VABYSMO may cause a temporary increase in eye pressure (intraocular pressure) in some patients within 60 minutes of the injection.
                         Your doctor will monitor this after each injection.</li>
                      <li>your doctor will check whether you have other risk factors that may increase the chance of a tear or detachment of one of the layers at
                         the back of the eye, in which case VABYSMO must be given with caution.</li>
                      <li>There is a risk of blood clots blocking blood vessels, which may lead to a heart attack or stroke with intravitreal injections of VABYSMO.
                         However, this is a theoretical risk. </li>
                    </ul>
                    <p>
                      Go to your nearest emergency room immediately if you experience signs of a stroke, such as weakness or paralysis of limbs or face or difficulty speaking.
                    </p>
                  </div>
              </div>
              <div className="col-12 col-lg-6">
                <div>
                  <p>
                    <b>Monitoring and care during your treatment with VABYSMO</b>
                  </p>
                  <p>Your doctor will monitor your condition throughout your treatment with VABYSMO to allow for prompt medical assistance if necessary.
                     Follow your doctor's instructions for how to care for the treated eye. These instructions include but are not limited to the following:</p>
                </div>
                <div>
                  <p>
                    <b>Driving or using machines</b>
                  </p>
                  <p>You may not see as well after Vabysmo is injected into your eye and after the eye examinations associated with Vabysmo.
                     Do not drive or use machines until your eyesight has returned to normal</p>
                </div>
                <div>
                  <p>
                    <b>Looking after your medicine</b>
                  </p>
                  <p>It is unlikely that you will have to store VABYSMO at home. If you have to store it:</p>
                  <ul>
                    <li>Store VABYSMO in the refrigerator at 2°C to 8°C in the original carton to protect from light.</li>
                    <li>Prior to use, the unopened vial may be kept at 20°C to 25°C for up to 24 hours.</li>
                    <li>Do not freeze. Do not shake.</li>
                    <li>Do not use this medicine after the expiry date.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
  )
}

export default ThingsToKnow;