import React from 'react';
import { TranslatedBlock } from 'src/components/language';
import '../pages/home/index.scss'

const SideEffects = () => {
  return (
    <section className="side-effects">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4 p-5 pl-0">
                <div>
                  <h2 className="mb-3 secondary3-color">What are possible side effects from using VABYSMO?</h2>
                  <p>
                    All medicines, including VABYSMO, can have side effects. If you do experience any side effects,
                     most of them are minor and temporary. However, some side effects may need medical attention.
                     See the information below and ask your doctor  if you have any further questions about the risk
                     of side effects, including any that are not listed here.
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-8">
                <div className="wrapper p-4 m-5">
                  <div className="row">
                    <TranslatedBlock language="english">
                      <div className="col-12">
                        <b className="secondary3-color">Possible common side effects include:</b>
                      </div>
                      <div className="col-12 col-md-12">
                        <ul>
                          <li>eye pain</li>
                          <li>burning, stinging, itching, redness, gritty or scratchy feeling</li>
                          <li>blurred, cloudy or decreased sharpness of vision or yellowing of colours</li>
                          <li>increased production of tears or watering eyes or eye discharge</li>
                          <li>a sensation that something is in your eye</li>
                          <li>moving spots (floaters) or shadows in your vision</li>
                          <li>small or distorted pupil</li>
                        </ul>
                      </div>
                      <p>Speak to your doctor if you have any of these side effects and they worry you. </p>
                    </TranslatedBlock>
                  </div>
                  <div className="row pt-3">
                    <TranslatedBlock language="english">
                      <div className="col-12">
                        <b className="secondary3-color">Serious side effects</b>
                      </div>
                      <div className="col-12 col-md-12">
                        <ul>
                          <li>
                          Pain, redness or blood around the colored part of the eye bleeding into the eye causing a red tint to your vision
                          </li>
                          <li>blood or redness in the white part of the eye</li>
                          <li>increase in eye pressure – symptoms include severe headache, eye pain, nausea and vomiting, blurred vision, eye redness</li>
                          <li>scratch to the clear part at the front of the eye</li>
                          <li>inflammation of the gel-like substance at the back of the eye – symptoms include hazy vision or a marked increase in floaters</li>
                          <li>sudden onset of the following: eye pain, increased discomfort, worsening eye redness, a higher number of floaters in your vision, or increased
                             sensitivity to light – these are signs of a possible eye infection or inflammation</li>
                          <li>decrease or change in your vision which can be sudden or gradual, including dim vision, difficulty with vision at night, fading sudden, brief flashes
                             of light which is more likely to occur when you move your eye, heavy feeling in the eye.</li>
                        </ul>
                      </div>
                      <p>Call your doctor immediately, or go straight to the Emergency Department at your nearest hospital if you notice any of these serious side effects.</p>
                    </TranslatedBlock>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  )
}

export default SideEffects;